






import { defineComponent } from '@vue/composition-api'

import { VideoProps } from '../../Video.contracts'
import { videoProps } from '../abstract'

import { useYoutubeVideo } from './YoutubeVideo.hooks'

export const YouTubeVideo = defineComponent({
  name: 'YouTubeVideo',
  props: videoProps,

  setup (props: VideoProps) {
    return useYoutubeVideo(props)
  }
})
export default YouTubeVideo
