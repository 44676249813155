









import { defineComponent } from '@vue/composition-api'

import { videoProps } from '../abstract'
import { VideoProps } from '../../Video.contracts'

import { useHTMLVideo } from './HTMLVideo.hooks'

export const HTMLVideo = defineComponent({
  name: 'HTMLVideo',
  props: videoProps,

  setup (props: VideoProps) {
    return useHTMLVideo(props)
  }
})
export default HTMLVideo
