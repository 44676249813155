

















import { defineComponent } from '@vue/composition-api'

import { CustomImage } from '../../atoms/Image'

import { videoProps } from './partials/abstract'
import { VideoProps } from './Video.contracts'
import { useVideo } from './Video.hooks'

export const Video = defineComponent({
  name: 'Video',
  components: { CustomImage },
  props: videoProps,

  setup (props: VideoProps) {
    return useVideo(props)
  }
})
export default Video
